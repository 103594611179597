import React from 'react';
import { Button } from 'fogg/ui';

import ContentRow from 'components/ContentRow';

const ContactCallout = () => {
  return (
    <ContentRow className="contact-callout" type={['center']}>
      <div>
        <h2 className="contentrow-title">Want to talk more about FilmDrop?</h2>
        <p>Get in touch, we&#39;d love to help out.</p>
        <p>
          <Button className="contact-button" to="/contact">
            Drop us a line
          </Button>
        </p>
      </div>
    </ContentRow>
  );
};

export default ContactCallout;
