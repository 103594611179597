import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

import Container from 'components/Container';

import CloudIcon from '../assets/images/icons/cloud.svg';
import SalesIcon from '../assets/images/icons/sales.svg';
import ArchivesIcon from '../assets/images/icons/archive.svg';
import SecurityIcon from '../assets/images/icons/security.svg';
import IntegrationIcon from '../assets/images/icons/integration.svg';
import AWSIcon from '../assets/images/icons/aws.svg';
import PaymentIcon from '../assets/images/icons/payment.svg';

const FeatureGrid = ({ children, className, feature }) => {
  const componentClassName = new ClassName('feature-row');

  if (className) {
    componentClassName.add(className);
  }

  return (
    <Container>
      <div className="filmdrop-core-features-container">
        <ul className="filmdrop-core-features">
          <li>
            <img src={CloudIcon} alt="Cloud-based icon" /> Cloud-based,
            instantly scalable to meet processing needs
          </li>
          <li>
            <img src={SalesIcon} alt="Sales icon" />
            Support for flexible sales and licensing models for data
          </li>
          <li>
            <img src={ArchivesIcon} alt="Archives icon" />
            Custom data products and Archives of Convenience
          </li>
          <li>
            <img src={SecurityIcon} alt="Security icon" />
            Security and access auditing
          </li>
          <li>
            <img src={IntegrationIcon} alt="Integration icon" />
            Integration with 3rd party account management systems (OAuth,
            Google)
          </li>
          <li>
            <img src={AWSIcon} alt="Archives icon" />
            Backed by the power, security, and compliance of AWS
          </li>
          <li>
            <img src={PaymentIcon} alt="Payment icon" />
            Payment processing
          </li>
        </ul>
      </div>
    </Container>
  );
};

FeatureGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  feature: PropTypes.node
};

export default FeatureGrid;
