import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'fogg/ui';
import { FaPlus } from 'react-icons/fa';

import Cards from 'components/Cards';
import Container from 'components/Container';
import ContentRow from 'components/ContentRow';
import FeatureRow from 'components/FeatureRow';
import FeatureList from 'components/FeatureList';
import Jumbotron from 'components/Jumbotron';
import Layout from 'components/Layout';
import MarketingVideos from 'components/MarketingVideos';
import Tag from 'components/Tag';
import ContactCallout from 'components/ContactCallout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FeatureGrid from 'components/FeatureGrid';

import filmDropLogo from '../assets/images/FilmDrop-LogoRiff.jpg';
import filmdropUiAuBushfires from '../assets/images/filmdrop-ui-au-bushfires.jpg';
import patagonia from '../assets/images/patagonia.png';
import filmdropUiCampfire from '../assets/images/filmdrop-ui-campfire.jpg';
import filmdropUiHurricaneMaria from '../assets/images/filmdrop-ui-hurricane-maria.jpg';
import filmdropUiDrone from '../assets/images/filmdrop-ui-drone.jpg';
import filmdropLaptopCampfire from '../assets/images/filmdrop-laptop-campfire.png';
import filmdropProcessingPipeline from '../assets/images/filmdrop-processing-pipeline.svg';

const FilmDropDrScreenshot = () => {
  return (
    <img
      className="image-box"
      src={filmdropUiCampfire}
      alt="FilmDrop DR UI Camp Fire"
    />
  );
};

const CampFireLaptopScreenshot = () => {
  return <img src={filmdropLaptopCampfire} alt="FilmDrop Laptop Camp Fire" />;
};

const IndexPage = () => {
  return (
    <Layout className="fullscreen">
      <Helmet bodyAttributes={{ class: 'page-home' }}>
        <title>
          FilmDrop - A Suite of Remote Sensing Processing and Management Tools
        </title>
      </Helmet>

      <Jumbotron>
        <img src={filmDropLogo} alt="FilmDrop logo" />
        <h1>
          FilmDrop
          <div className="header-tags">
            <Tag color="filmdrop-dr">DR</Tag>
            <FaPlus className="icon-plus" />
            <Tag color="filmdrop-core">CORE</Tag>
          </div>
        </h1>
        <p>A Suite of Remote Sensing Processing and Management Tools</p>
      </Jumbotron>

      <FeatureRow feature={<FilmDropDrScreenshot />}>
        <h2 className="contentrow-title">
          FilmDrop
          <Tag color="filmdrop-dr">DR</Tag>
        </h2>
        <p className="contentrow-text">
          Element 84’s FilmDrop DR product incorporates a decade of experience
          in remote sensing data processing and user interfaces to bring
          information from drones, public and private satellites, and field
          teams together in an AWS Snowball Edge-deployable interface to support
          disaster and public safety teams. With a focus on time to deployment,
          FilmDrop DR minimizes time to insight during critical moments.
        </p>
        <p>
          <Button className="contact-button" to="/contact">
            Contact Us
          </Button>
        </p>
      </FeatureRow>

      <Container type="full" color="gray-light">
        <h3 className="contentrow-title contentrow-title-centered">
          FilmDrop
          <Tag color="filmdrop-dr">DR</Tag>
          In Action
        </h3>
        <MarketingVideos
          videoSrcURL="https://www.youtube.com/embed/E_xHnfsvl2w"
          videoTitle="Improving Information and Communications in a Disaster Scenario with AWS Snowball Edge"
        />
        <Container>
          <Tabs>
            <TabList>
              <Tab>
                <Tag color="filmdrop-dr">DR</Tag>{' '}
                <span className="tab-text">Features</span>
              </Tab>
              <Tab>
                <Tag color="filmdrop-dr">DR</Tag>{' '}
                <span className="tab-text">Demos</span>
              </Tab>
            </TabList>
            <TabPanel>
              <FeatureList
                features={[
                  {
                    topic: 'Deployable in the field via Snowball Edge',
                    description:
                      'The AWS Snowball Edge gives us the ability to extend familiar tools to an offline environment - decreasing cognitive overhead and time-to-learn.'
                  },
                  {
                    topic: 'Users ready to operate in less than 15 minutes',
                    description:
                      'Designed to teach to an operator in less than 15 minutes, a basic map layout and simple interface allows for extremely rapid response without bells & whistles.  '
                  },
                  {
                    topic: 'Integrate in-situ data from disparate sources',
                    description:
                      'Flexible basemaps including data from OpenStreetMap and USGS'
                  },
                  {
                    topic: 'Drone data processing',
                    description:
                      'Process raw drone imagery into GeoTIFFs and tiles for use in FilmDrop DR map layers Self-contained OpenDroneMap instance can process data in the field, reducing time to analysis'
                  },
                  {
                    topic: 'Deployable via AWS Cloud ',
                    description:
                      'Take advantage of the deeper processing power of the FilmDrop Core stack'
                  },
                  {
                    topic: 'Support for offline capabilities and sync',
                    description:
                      'View tiling & geojson data in offline capacity.  Import custom data points, boundaries, and layers offline.'
                  },
                  {
                    topic: 'Timeline functionality',
                    description:
                      'Timeline allows for 360 degree “playback” of events for research and retrospectives.'
                  }
                ]}
              />
            </TabPanel>
            <TabPanel>
              <Cards
                heading="Patagonia Fires"
                description=" "
                backgroundImg={patagonia}
                to="/event/patagonia"
              />
              <Cards
                heading="Australian Bushfires"
                description=" "
                backgroundImg={filmdropUiAuBushfires}
                to="/event/au-bushfires"
              />
              <Cards
                heading="Camp Fire"
                description="Our interviews with the Butte County Search and Rescue team and their case studies from the 2018 California Camp Fire informed much of the original development of FilmDrop DR."
                backgroundImg={filmdropUiCampfire}
                to="/event/campfire"
              />
              <Cards
                heading="Hurricane Maria"
                description="Hurricane Maria was the worst natural disaster in recorded history to impact Puerto Rico. Using the VIIRS Nightime imagery you can see the direct impact to the national power grid."
                to="/event/hurricane-maria"
                backgroundImg={filmdropUiHurricaneMaria}
              />
              <Cards
                heading="OpenDroneMap Imagery"
                description="OpenDroneMap processes geo-tagged imagery from consumer and professional drones and creates composite GeoTIFFs and tiled imagery that can be used in FilmDrop DR layers."
                to="/event/dronedemo"
                backgroundImg={filmdropUiDrone}
              />
            </TabPanel>
          </Tabs>
        </Container>
      </Container>

      <FeatureRow feature={<CampFireLaptopScreenshot />}>
        <h2 className="contentrow-title">
          FilmDrop
          <Tag color="filmdrop-core">CORE</Tag>
        </h2>
        <p>
          FilmDrop Core lets you create a ephemeral archives of convenience
          (AoC) and custom pipelines that make remote sensing and in-situ data
          more useable and actionable.
        </p>
        <p>
          <Button className="contact-button" to="/contact">
            Contact Us
          </Button>
        </p>
      </FeatureRow>

      <FeatureGrid />
      <Container type="full" color="gray-light">
        <ContentRow>
          <div>
            <h3 className="contentrow-title align-center">More Features</h3>
            <FeatureList
              features={[
                {
                  topic: 'Ground Stations and Data Acquisition',
                  description:
                    'FilmDrop can use Existing S3 buckets as a data ingest source or automatically connect to your AWS Ground Station.'
                },
                {
                  topic: 'Archive and Storage',
                  description:
                    'Automatically store data based on planned use and let our FilmDrop engine help you build and index your metadata.'
                },
                {
                  topic: 'Processing Pipelines',
                  description:
                    'Generate ephemeral archives of convenience (AoC) and custom pipelines to make your data more useable.'
                },
                {
                  topic: 'Discovery and Access',
                  description:
                    'Search, discover, and visualize your data at scale and make it available via services for your team or customers.'
                }
              ]}
            />
          </div>
        </ContentRow>

        <ContentRow>
          <div>
            <h3 className="contentrow-title align-center">How It Works</h3>
            <img src={filmdropProcessingPipeline} alt="Filmdrop Flowchart" />
          </div>
        </ContentRow>
      </Container>

      <Container>
        <ContactCallout />
      </Container>
    </Layout>
  );
};

export default IndexPage;
