import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

const Tag = ({ children, color }) => {
  const componentClassName = new ClassName('tag');

  if (color) {
    componentClassName.add(componentClassName.child(`color-${color}`));
  }

  return <span className={componentClassName.string}>{children}</span>;
};

Tag.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string
};

export default Tag;
