import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Button } from 'fogg/ui';

import ClassName from 'models/classname';

const Cards = ({ heading, description, backgroundImg, to }) => {
  const cardClassName = new ClassName('card');
  return (
    <>
      <div className={cardClassName.string}>
        <div className={cardClassName.child('content')}>
          <h3 className={cardClassName.child('heading')}>{heading}</h3>
          <p className={cardClassName.child('description')}>{description}</p>
          {to && (
            <p>
              <Button type="text" to={to}>
                Visit Demo
              </Button>
            </p>
          )}
        </div>
        <Link
          className={cardClassName.child('image')}
          to={to}
          style={{
            backgroundImage: `url(${backgroundImg})`
          }}
        ></Link>
      </div>
    </>
  );
};

Cards.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
  backgroundImg: PropTypes.string
};

export default Cards;
