import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

import ContentRow from 'components/ContentRow';

const FeatureRow = ({ children, className, feature }) => {
  const componentClassName = new ClassName('feature-row');

  if (className) {
    componentClassName.add(className);
  }

  return (
    <ContentRow className="container">
      <div className="row vertical-center mobile-reverse-order">
        <div className="col-6">{children}</div>
        <div className={'col-6 ' + componentClassName.child('feature')}>
          {feature}
        </div>
      </div>
    </ContentRow>
  );
};

FeatureRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  feature: PropTypes.node
};

export default FeatureRow;
