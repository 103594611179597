import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

const MarketingVideos = ({ className, videoSrcURL, videoTitle }) => {
  const componentClassName = new ClassName('marketing-videos');

  if (className) {
    componentClassName.add(className);
  }

  return (
    <section className="iframe-container">
      <iframe
        title={videoTitle}
        src={videoSrcURL}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </section>
  );
};

MarketingVideos.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  videoSrcURL: PropTypes.string,
  videoTitle: PropTypes.string
};

export default MarketingVideos;
