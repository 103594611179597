import React from 'react';
import PropTypes from 'prop-types';

import ClassName from 'models/classname';

import Container from 'components/Container';

const ContentRow = ({ children, className, containerType, type }) => {
  const componentClassName = new ClassName('contentrow');

  if (typeof type === 'string') {
    type = [type];
  }

  if (Array.isArray(type)) {
    type.forEach(t => componentClassName.add(componentClassName.child(t)));
  }

  if (className) {
    componentClassName.add(className);
  }

  return (
    <Container className={componentClassName.string} type={containerType}>
      <div className={componentClassName.child('content')}>{children}</div>
    </Container>
  );
};

ContentRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerType: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ContentRow;
