import React from 'react';
import PropTypes from 'prop-types';

const FeatureList = ({ features = [] }) => {
  return (
    <ul className="feature-list">
      {Array.isArray(features) &&
        features.map((feature, index) => {
          const { topic, description } = feature;
          return (
            <li className={`feature-list-item-${topic}`} key={index}>
              {topic && <h3>{topic}</h3>}
              {description && <p>{description}</p>}
            </li>
          );
        })}
    </ul>
  );
};

FeatureList.propTypes = {
  features: PropTypes.array
};

export default FeatureList;
